 import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";

import staffLockerRoom from './images/applications/staff-locker-rooms.jpg';
import merchDisplays from './images/applications/merchandise.jpg';
import chargingLockers from './images/applications/charging-lockers.jpg';
import customerLockers from './images/applications/customer-lockers.jpg';

const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const projectAsides = [
    '<span>the netherlands</span><br>' +
    '<h2>Tommy Hilfiger</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Sola Keypad</a></p>',

    '<span>canada</span><br>' +
    '<h2>La Maison Simons</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Joann Fabrics</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Lockup Lockers Keypad</a></p>',

    '<span>estonia</span><br>' +
    '<h2>Estonian Mall</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Bubble Stitch</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Classic Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Unilever</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>latvia</span><br>' +
    '<h2>Riga Mall</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Walgreens</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Lockup Lockers Keypad</a></p>'
  ];

  const solutions = [
    {
      img: staffLockerRoom,
      label: 'retail_locker_rooms',
      alt: 'staff_locker_rooms',
      bg: 'light'
    },
    {
      img: merchDisplays,
      label: 'merch_displays',
      alt: 'merch_displays',
      bg: 'light'
    },
    {
      img: chargingLockers,
      label: 'juicebar_charging_lockers',
      alt: 'juicebar_charging_lockers',
      bg: 'light'
    },
    {
      img: customerLockers,
      label: 'customer_lockers',
      alt: 'customer_lockers',
      bg: 'dark'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_retail')}
        description={t('des_solutions_retail')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-retail.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Retail"
            />
            <h1>
              <Trans>
                retail
              </Trans>
              <span>
                <Trans>
                  retail_hero_subhead
                </Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>retail_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>retail_subhero_list_l1</Trans></li>
                  <li><Trans>retail_subhero_list_l2</Trans></li>
                  <li><Trans>retail_subhero_list_l3</Trans></li>
                  <li><Trans>retail_subhero_list_l4</Trans></li>
                  <li><Trans>retail_subhero_list_l5</Trans></li>
                  <li><Trans>retail_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/retail.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="workspace office"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup
              label={'retail_lineup_label'}
              showCurve={false}
              showAspire={true}
              showVersa={true}
              showOrbit={true}
              showRange={true}
              showCleo={true}
              showCTA={true}
          />
          <section className="industry-carousel">
            <div className="container">
              <h2>
                <Trans>
                  retail_carousel
                </Trans>
              </h2>
              <GatsbySlideshow images={images} overlays={projectAsides} />
            </div>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2>
                  <Trans>
                    retail_convenient
                  </Trans>
                </h2>
                <p>
                  <Trans>retail_copy</Trans>
                </p>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/retail2.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="retail locker"
                />
              </div>
            </div>
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

 export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/retail"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
